import { configureStore } from '@reduxjs/toolkit';
import createDebounce from 'redux-debounced';
import { persistReducer, persistStore } from 'redux-persist';
/* eslint-disable import/no-unresolved */
import { encryptTransform } from 'redux-persist-transform-encrypt';
/* eslint-enable import/no-unresolved */
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './slices/rootReducer';

const middleware = [createDebounce()];

// Encryption key
const encryptionKey = process.env.REACT_APP_CHAT_SECRET_KEY;

// Encryption transform configuration
const encryptor = encryptTransform({
  secretKey: encryptionKey,
  onError(error) {
    console.log('🚀 ~ onError ~ error:', error);
    // Handle the error.
  },
});

// Creating persistent Store with encryption
const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Creating Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

export { store, persistor };
