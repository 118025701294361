import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentOrderUpdate: null,
  currentNewOrderUpdate: null,
  currentDeleteOrderUpdate: null,
  unreadOrderCount: 0,
  onUpdateUnreadOrderCountSubscriptionStream: null,
  chartType: '2d',
  isExpandedChart: true,
  orderSigns: [],
};
/* eslint-disable */
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCurrentOrderUpdate: (state, { payload }) => {
      state.currentOrderUpdate = payload;
    },
    setCurrentNewOrderUpdate: (state, { payload }) => {
      state.currentNewOrderUpdate = payload;
    },
    setCurrentDeleteOrderUpdate: (state, { payload }) => {
      state.currentDeleteOrderUpdate = payload;
    },
    incrementUnreadOrderCount: (state, { payload }) => {
      state.unreadOrderCount = payload;
    },
    setUpdateUnreadOrderCountSubscriptionStream: (state, { payload }) => {
      state.onUpdateUnreadOrderCountSubscriptionStream = payload;
    },
    setChartTypeUpdate: (state, { payload }) => {
      state.chartType = payload;
    },
    setIsExpandedChart: (state, { payload }) => {
      state.isExpandedChart = payload;
    },
    setOrderSigns: (state, { payload }) => {
      state.orderSigns = payload;
    },
  },
});

export const selectCurrentOrderUpdate = (state) =>
  state.orderReducer.currentOrderUpdate;
export const selectCurrentNewOrderUpdate = (state) =>
  state.orderReducer.currentNewOrderUpdate;
export const selectCurrentDeleteOrderUpdate = (state) =>
  state.orderReducer.currentDeleteOrderUpdate;
export const selectUnreadOrderCount = (state) =>
  state.orderReducer.onUpdateUnreadOrderCountSubscriptionStream;
export const selectChartType = (state) => state.orderReducer.chartType;
export const selectIsExpandedChart = (state) =>
  state.orderReducer.isExpandedChart;
export const selectOrderSigns = (state) => state.orderReducer.orderSigns;

const { actions, reducer } = orderSlice;

export const {
  setCurrentOrderUpdate,
  setCurrentNewOrderUpdate,
  setCurrentDeleteOrderUpdate,
  incrementUnreadOrderCount,
  setUpdateUnreadOrderCountSubscriptionStream,
  setChartTypeUpdate,
  setIsExpandedChart,
  setOrderSigns,
} = actions;
export default reducer;
