import { combineReducers } from 'redux';

import countReducer from './countSlice';
import csvDataReducer from './csvDataSlice';
import invoiceReducer from './invoiceSlice';
import loginReducer from './loginSlice';
import messageReducer from './messageSlice';
import orderReducer from './orderSlice';
import ticketReducer from './supportTicketSlice';

const appReducer = combineReducers({
  loginReducer,
  countReducer,
  messageReducer,
  csvDataReducer,
  orderReducer,
  invoiceReducer,
  ticketReducer,
});
/* eslint-disable */
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.clear();
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
