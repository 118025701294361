import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allUsersCount: 0,
  activeUsersCount: 0,
  hospitalsCount: 0,
  vendorsCount: 0,
  vendorsTabCount: 0,
  pendingTabCount: 0,
  unreadNotiCount: 0,
  unreadSupportTicketNotiCount: 0,
  isNotifyDrawer: false,
  isRefreshStatistics: false,
};
/* eslint-disable */
const countSlice = createSlice({
  name: 'countSlice',
  initialState,
  reducers: {
    updateAllUsersCount: (state, { payload }) => {
      state.allUsersCount = payload;
    },
    updateActiveUsersCount: (state, { payload }) => {
      state.activeUsersCount = payload;
    },
    updateHospitalsCount: (state, { payload }) => {
      state.hospitalsCount = payload;
    },
    updateVendorsCount: (state, { payload }) => {
      state.vendorsCount = payload;
    },
    updateVendorsTabCount: (state, { payload }) => {
      state.vendorsTabCount = payload;
    },
    updatePendingTabCount: (state, { payload }) => {
      state.pendingTabCount = payload;
    },
    unreadNotificationCount: (state, { payload }) => {
      state.unreadNotiCount = payload;
    },
    unreadSupportTicketNotificationCount: (state, { payload }) => {
      state.unreadSupportTicketNotiCount = payload;
    },
    openNotifyDrawer: (state, { payload }) => {
      state.isNotifyDrawer = payload;
    },
    refreshStatistics: (state, { payload }) => {
      state.isRefreshStatistics = payload;
    },
  },
});

const { actions, reducer } = countSlice;
export const {
  updateAllUsersCount,
  updateActiveUsersCount,
  updateHospitalsCount,
  updateVendorsCount,
  updateVendorsTabCount,
  updatePendingTabCount,
  unreadNotificationCount,
  unreadSupportTicketNotificationCount,
  openNotifyDrawer,
  refreshStatistics,
} = actions;
export default reducer;
