import { createSlice } from '@reduxjs/toolkit';

import { USER_ROLE } from 'utils/constants/global';

const {
  FACILITY_ACCOUNT_MANAGER,
  FACILITY_USER,
  PROVIDER_EXECUTIVE_ADMIN,
  PROVIDER_USER,
  SUPER_VENDOR,
  VENDOR_ACCOUNT_MANAGER,
  VENDOR_USER,
} = USER_ROLE;

// Initialize Broadcast Channel
const authChannel = new BroadcastChannel('auth_channel');

const initialState = {
  groups: [],
  token: null,
  userData: null,
  epicUrl: null,
  rolePermission: '',
  planFeatures: [],
  planID: null,
  reportingOptions: [],
};
/* eslint-disable */
const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    signIn: (state, { payload }) => {
      state.token = payload.token;
      state.groups = payload.groups;
      state.userData = payload.userData;
      state.planFeatures = payload.planFeatures;
      state.planID = payload.planID;
      // Broadcast login state update
      authChannel.postMessage({ type: 'SIGN_IN', payload });
    },
    signOut: (state) => {
      // Clear state on logout
      state.token = null;
      state.groups = [];
      state.userData = null;
      state.rolePermission = '';
      // Broadcast logout state update
      authChannel.postMessage({ type: 'SIGN_OUT' });
    },
    handleBroadcastedAuthState: (state, { payload }) => {
      // Handle broadcasted state based on the type
      if (payload.type === 'SIGN_IN') {
        return { ...state, ...payload.payload };
      } else if (payload.type === 'SIGN_OUT') {
        return { ...initialState };
      }
    },
    updateUserData: (state, { payload }) => {
      state.userData = {
        ...state.userData,
        ...payload, // Merge existing userData with the new payload
      };
    },
    updateEpicUrl: (state, { payload }) => {
      state.epicUrl = payload; // Set the epicUrl property to the new value
    },
    updatePlanFeatures: (state, { payload }) => {
      state.planFeatures = payload;
    },
    updatePlanID: (state, { payload }) => {
      state.planID = payload;
    },
    updateReportingOptions: (state, { payload }) => {
      state.reportingOptions = payload;
    },
  },
});

export const getCurrentToken = (state) => state?.loginReducer?.token;
export const getUserData = (state) => state?.loginReducer?.userData;
export const getEpicUrl = (state) => state?.loginReducer?.epicUrl;
export const getPermission = (state) => state?.loginReducer?.rolePermission;
export const isHospitalUser = (state) =>
  state?.loginReducer?.groups?.includes('HOSPITAL') &&
  (state?.loginReducer?.userData?.role === FACILITY_ACCOUNT_MANAGER ||
    state?.loginReducer?.userData?.role === FACILITY_USER);
export const isVendorUser = (state) =>
  state?.loginReducer?.groups?.includes('VENDOR') &&
  (state?.loginReducer?.userData?.role === VENDOR_ACCOUNT_MANAGER ||
    state?.loginReducer?.userData?.role === VENDOR_USER);
export const isSuperVendorUser = (state) =>
  state?.loginReducer?.groups?.includes('VENDOR') &&
  state?.loginReducer?.userData?.role === SUPER_VENDOR;
export const isAdminUser = (state) =>
  state?.loginReducer?.groups?.includes('ADMIN');
export const isProviderUser = (state) =>
  state?.loginReducer?.groups?.includes('HOSPITAL') &&
  (state?.loginReducer?.userData?.role === PROVIDER_EXECUTIVE_ADMIN ||
    state?.loginReducer?.userData?.role === PROVIDER_USER);
export const isLinkedAccount = (state) => {
  const isHospital = state?.loginReducer?.groups?.includes('HOSPITAL');
  const accessToken = state?.loginReducer?.userData?.accessToken;
  return isHospital && Boolean(accessToken) && accessToken !== '';
};
const { actions, reducer } = loginSlice;
export const {
  signIn,
  signOut,
  handleBroadcastedAuthState,
  updateUserData,
  updateEpicUrl,
  updatePlanFeatures,
  updatePlanID,
  updateReportingOptions,
} = actions;
export default reducer;
