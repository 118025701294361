import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  csvData: [],
};
/* eslint-disable */
const csvDataSlice = createSlice({
  name: 'csvDataSlice',
  initialState,
  reducers: {
    updateCsvData: (state, { payload }) => {
      state.csvData = payload;
    },
  },
});

export const getCsvData = (state) => state?.csvDataReducer?.csvData;
const { actions, reducer } = csvDataSlice;
export const { updateCsvData } = actions;
export default reducer;
