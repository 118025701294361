import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentInvoiceUpdate: null,
};
/* eslint-disable */
const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setCurrentInvoiceUpdate: (state, { payload }) => {
      state.currentInvoiceUpdate = payload;
    },
  },
});

export const selectCurrentInvoiceUpdate = (state) =>
  state.invoiceReducer.currentInvoiceUpdate;

const { actions, reducer } = invoiceSlice;
export const { setCurrentInvoiceUpdate } = actions;
export default reducer;
