import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  room: [],
  onCreateRoom: null,
  onUpdateUnreadChatCount: null,
};

/* eslint-disable */
const messageSlice = createSlice({
  name: 'messageSlice',
  initialState,
  reducers: {
    setOnCreateRoom: (state, { payload }) => {
      state.onCreateRoom = payload;
    },
    setOnUpdateUnreadChatCount: (state, { payload }) => {
      state.onUpdateUnreadChatCount = payload;
    },
  },
});

export const getUnreadChatCount = (state) =>
  state?.messageReducer?.onUpdateUnreadChatCount;

const { actions, reducer } = messageSlice;

export const { setOnCreateRoom, setOnUpdateUnreadChatCount } = actions;

export default reducer;
