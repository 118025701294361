import setupLocatorUI from '@locator/runtime';
import moment from 'moment';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import Spinner from 'components/base/Spinner';
import 'moment/locale/en-gb';
import { persistor, store } from 'store/store'; // createRoot(container!) if you use TypeScript  React-18

const container = document.getElementById('root');
const root = createRoot(container);
// Example for English (United Kingdom)
moment.locale('en-US');
// CSS import for phone number input field, Basic Shift CSS file

// ** Lazy load app
/* Lazy loading the app */
const LazyApp = React.lazy(() => import('./App'));
const queryClient = new QueryClient();

if (process.env.NODE_ENV === 'development') {
  setupLocatorUI();
}

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate persistor={persistor}>
        <React.Suspense fallback={<Spinner />}>
          <Router>
            <LazyApp />
          </Router>
        </React.Suspense>
      </PersistGate>
    </QueryClientProvider>
  </Provider>,
);
