import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { memo } from 'react';

const antIcon = (
  <LoadingOutlined
    spin
    style={{
      fontSize: 24,
    }}
  />
);
function Spinner({ className }) {
  return <Spin className={className} indicator={antIcon} />;
}

export default memo(Spinner);
