import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  createTicketSubscriptionStream: null,
  updateTicketSubscriptionStream: null,
  createTicketMessageSubscriptionStream: null,
};

/* eslint-disable */
const supportTicketSlice = createSlice({
  name: 'supportTicketSlice',
  initialState,
  reducers: {
    setCreateTicketSubscriptionStream: (state, { payload }) => {
      state.createTicketSubscriptionStream = payload;
    },
    setUpdateTicketSubscriptionStream: (state, { payload }) => {
      state.updateTicketSubscriptionStream = payload;
    },
    setCreateTicketMessageSubscriptionStream: (state, { payload }) => {
      state.createTicketMessageSubscriptionStream = payload;
    },
  },
});

export const selectCreateTicketStream = (state) =>
  state.ticketReducer.createTicketSubscriptionStream;

export const selectUpdateTicketStream = (state) =>
  state.ticketReducer.updateTicketSubscriptionStream;

const { actions, reducer } = supportTicketSlice;
export const {
  setCreateTicketSubscriptionStream,
  setUpdateTicketSubscriptionStream,
  setCreateTicketMessageSubscriptionStream,
} = actions;

export default reducer;
